// Generated by Framer (d2515d1)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Feather } from "https://framerusercontent.com/modules/f0DboytQenYh21kfme7W/zb1zVBMZJKgPMiedOi0y/Feather.js";
const FeatherFonts = getFonts(Feather);

const enabledGestures = {qVMVcg6Wy: {hover: true}};

const cycleOrder = ["qVMVcg6Wy"];

const serializationHash = "framer-OuQ7M"

const variantClassNames = {qVMVcg6Wy: "framer-v-1197ejb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, width, ...props}) => { return {...props, oAOLfNJCU: link ?? props.oAOLfNJCU} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, oAOLfNJCU, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "qVMVcg6Wy", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={oAOLfNJCU}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1197ejb", className, classNames)} framer-lpgz3w`} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"qVMVcg6Wy"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-c7b016ac-a10b-4622-acaf-8a1593f166fd, rgb(255, 255, 255))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: "50%", borderBottomRightRadius: "50%", borderTopLeftRadius: "50%", borderTopRightRadius: "50%", ...style}} variants={{"qVMVcg6Wy-hover": {backgroundColor: "var(--token-c7b016ac-a10b-4622-acaf-8a1593f166fd, rgb(255, 255, 255))"}}} {...addPropertyOverrides({"qVMVcg6Wy-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-1j9s7tt-container"} data-framer-name={"icon"} layoutDependency={layoutDependency} layoutId={"c4qjENsUR-container"} name={"icon"} style={{rotate: 0}} variants={{"qVMVcg6Wy-hover": {rotate: -45}}}><Feather color={"var(--token-c7b016ac-a10b-4622-acaf-8a1593f166fd, rgb(255, 255, 255))"} height={"100%"} iconSearch={"Home"} iconSelection={"arrow-right"} id={"c4qjENsUR"} layoutId={"c4qjENsUR"} mirrored={false} name={"icon"} selectByList style={{height: "100%", width: "100%"}} width={"100%"} {...addPropertyOverrides({"qVMVcg6Wy-hover": {color: "var(--token-1339643a-2c51-41f4-85c3-1fa1d2729bd9, rgb(0, 62, 126))"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-OuQ7M.framer-lpgz3w, .framer-OuQ7M .framer-lpgz3w { display: block; }", ".framer-OuQ7M.framer-1197ejb { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 50px; justify-content: center; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: 50px; will-change: var(--framer-will-change-override, transform); }", ".framer-OuQ7M .framer-1j9s7tt-container { flex: none; height: 30px; position: relative; width: 26px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-OuQ7M.framer-1197ejb { gap: 0px; } .framer-OuQ7M.framer-1197ejb > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-OuQ7M.framer-1197ejb > :first-child { margin-left: 0px; } .framer-OuQ7M.framer-1197ejb > :last-child { margin-right: 0px; } }", ".framer-OuQ7M.framer-v-1197ejb.hover.framer-1197ejb { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 50px); }", ".framer-OuQ7M[data-border=\"true\"]::after, .framer-OuQ7M [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 50
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"waBy20NgJ":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"oAOLfNJCU":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerAIfEALUCr: React.ComponentType<Props> = withCSS(Component, css, "framer-OuQ7M") as typeof Component;
export default FramerAIfEALUCr;

FramerAIfEALUCr.displayName = "Circle Learn more";

FramerAIfEALUCr.defaultProps = {height: 50, width: 50};

addPropertyControls(FramerAIfEALUCr, {oAOLfNJCU: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerAIfEALUCr, [{explicitInter: true, fonts: []}, ...FeatherFonts], {supportsExplicitInterCodegen: true})